import React from "react";

import "../assets/styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <p className="disclaimer text-white text-justify">
        “La rentabilidad es variable, por lo que nada garantiza que las rentabilidades pasadas se repitan en el futuro. Infórmese
        sobre la rentabilidad de su Fondo de Pensiones, las comisiones y la calidad de servicio de las AFP en el sitio web de la
        Superintendencia de Pensiones:{" "}<a href="http://www.spensiones.cl"> www.spensiones.cl</a>”. Fuente: Inversiones y 
        Rentabilidad de los Fondos de Pensiones correspondiente a agosto 2024. Periodo correspondiente a septiembre 2023 — agosto 2024. 
        Rentabilidad de la cuota expresada en forma real: Fondo A: 11,19%, Fondo B:  10,08%, Fondo C: 8,17%, Fondo D: 6,60%, Fondo E: 
        6,21% (quinto lugar). Estructura de comisiones por depósitos de cotizaciones vigentes a septiembre de 2024 - Capital: 1,44%, 
        Cuprum: 1,44%, Habitat: 1,27%, Modelo: 0,58%, Planvital: 1,16%, Provida: 1,45%, Uno: 0,49% - Para afiliados dependientes, 
        independientes y voluntarios. Número de afiliados por AFP vigente según Informe Estadístico Mensual de Afiliados y Cotizantes 
        de la Superintendencia de Pensiones (2.803.083 afiliados al 30 de junio de 2024). Primer lugar en experiencia de servicio 
        según ranking PXI – Praxis Xperience Index 2024 para el Sector AFP.

      </p>
    </footer>
  );
};

export default Footer;
